import React from 'react';
import cookie from 'react-cookies';
import * as r from "ramda";

export const projectState = {
    id: cookie.load('projectId'),
    name: cookie.load('projectName'),
    upData: () => {}
}

export const Project = React.createContext({
    projectState,
    upData: () => {}
});

export const rightFormItemLayout = {
    labelCol: {
        span: 4,
        // offset: 3
    },
    wrapperCol: {
        span: 21,
        offset: 3
    },
};


export const updateProjectFormLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 21,
        offset: 8,
        pull: 5
    },
};

export const groupFormItemLayout = {
    span: 4
}

export const projectDefaultModules = {
    'BASIC': {
        web: {
            'dashboard': '首页',
            'devices': '设备管理',
            'statistics': '统计报表',
            'presentation': '首页投屏',
        },
        admin: {
            'information': '信息概览',
            'building': '建筑管理',
            'account': '账号管理',
            'collector': '管理器',
            'billingservice': '计费策略',
            'byitem': '分项用能',
            'node': '建筑结构',
        }
    },
    'ANALYSIS': {
        web: {
            'dashboard': '首页',
            'devices': '设备管理',
            'statistics': '统计报表',
            'presentation': '首页投屏',
            'analysis': '综合分析',
        },
        analysis: {
            'analysis_energy': '能耗分析',
            'analysis_subentry': '分项用能',
            'analysis_daynight': '日夜间',
            'analysis_building': '建筑分析',
            'analysis_department': '部门分析'
        },
        admin: {
            'information': '信息概览',
            'building': '建筑管理',
            'account': '账号管理',
            'collector': '管理器',
            'billingservice': '计费策略',
            'byitem': '分项用能',
            'node': '建筑结构',
            'bybuilding': '建筑属性',
            'byapart': '部门属性',
        },
    }
}
export const projectModules = {
    web: {
        'dashboard': '首页',
        'devices': '设备管理',
        'devicesV2': '设备管理V2',
        'statistics': '统计报表',
        'presentation': '首页投屏',
        'analysis': '综合分析',
        'statisticsRegion': '区域统计报表',//20200303 ltx2
        'weatherDate': '天气数据统计',//20200310 ltx
        'airdata': '空气数据统计',//20200313 ltx
        'noise': '噪声',//20200715 ltx
        'average_building': '建筑能耗对比',//20200401 ltx
        'old_data': '老数据',//20200410 ltx
        'regionDashboard': '区域首页',//20200426
        'regionPresentation': '区域投屏',//20200426
        'presentationV2': '投屏版V2',//20200611 修改
        'reportTemplates': '报告模板',//20200514
        'statisAllStates': '状态统计',//20201030
        'carbonNeutral': '碳中和',//20210519
        'registerRegion': '注册查看',//20210715
        'drawingMonitor': '审图查看',//20210723
        'pvdata': '光伏数据统计',//20200313 ltx
        'sms': '短信',//20200313 ltx
    },
    analysis: {
        'analysis_energy': '能耗分析',
        'analysis_subentry': '分项用能',
        'analysis_daynight': '日夜间',
        'analysis_building': '建筑分析',
        'analysis_department': '部门分析',
    },
    admin: {
        'information': '信息概览',
        'building': '建筑管理',
        'account': '账号管理',
        'collector': '管理器',
        'report': '单项报告',
        'reportAll': '总体报告',
        'editMeter': '电表信息',
        'billingservice': '计费策略',
        'byitem': '分项用能',
        'node': '建筑结构',
        'bybuilding': '建筑属性',
        'byapart': '部门属性',
    },
    extra: {
        'map': '能耗地图',
        'grid_monitor': '电网监控',
        'water_monitor': '水网监控',
    }
}

export const formItemLayout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 12,
    },
};

export const rightRadioFormItemLayout = {
    labelCol: {
        span: 3,
        // offset: 3
    },
    wrapperCol: {
        span: 21,
        offset: 3,
        pull: 5
    },
};
export const tailFormItemLayout = {
    wrapperCol: {
        span: 24,
        // offset: 0,
    },
};

export const projectType = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}
export const projectSource = {
    SELF: '自有',
    AGENT: '代理商',
    FACTORY: '厂家',
    INTEG: '集成商',
    RUNOP: '运营方',
    NMKT: '网络销售',
}

export const joinAllModules = (web, admin, analysis, extra) => r.flatten([web, admin, (r.includes('analysis', web) ? analysis : []), extra])